<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItemsFiltrados" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import {mapState} from "vuex";

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  computed: {
    ...mapState({
      laboratorio: state => state.laboratorios.configuracaoLaboSelecionado
    }),
    navMenuItemsFiltrados() {
      let menu = []

      this.navMenuItems.forEach( (v) => {
        if(!this.laboratorio.odonto_financeiro) {
          if(v.route !== 'financeiro') {
            menu.push(v)
          }
        }else{
          menu.push(v)
        }
      })

      return menu
    }
  },

}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
